html,
body {
  background-color: #f1f2f5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  max-height: 600px;
  overflow: scroll;
  border: 1px solid #eee;
  background-color: #fefefe;
  border-radius: 6px;
  margin: 20px 0;
  display: block;
  padding: 20px;
  max-width: 40vw;
}

.download {
  float: right;
}

.loadData {
  display: block;
}
